.client-report-description {
  width: 886px;
  max-width: 886px;
  margin: 0 auto;
  margin-bottom: 6rem;
  margin-top: 0.4rem;
  @media (max-width: 920px) {
    width: 100%;
    text-align: center;
    padding: 0 1rem;
  }
}

.client-report {
  display: grid;
  grid-template-columns: repeat(2, 0fr);
  gap: 2rem;
  height: fit-content;
  align-items: stretch;
  justify-items: start;
  justify-content: center;

  @media (max-width: 920px) {
    grid-template-columns: 2fr;
  }

  .card {
    min-width: 330px;
    width: 433px;
    max-width: 433px;
    height: fit-content;
    padding: 1rem;
    text-align: center;
    max-height: 245px;
    min-height: 136px;
    &.primary {
      height: 245px;
    }
    &.secondary {
      height: 136px;
    }

    @media (max-width: 440px) {
      padding: 0.5rem;
      &.primary {
        width: 330px;
        height: 230px;
      }
      &.secondary {
        width: 330px;
        height: 136px;
      }
    }
  }
}
