.table-container {
  width: 100%;
}
.data-table {
  width: 100%;
  border-spacing: 0;
  padding: 0.5rem;
  &.clickeable tr {
    cursor: pointer;
  }
  th {
    font-weight: 700;
    font-size: 1.4rem;
    white-space: normal;
    word-break: break-word;
    text-overflow: ellipsis;
    overflow: hidden;
    text-align: start;
    #sortingIcon {
      opacity: 0;
    }
    div {
      gap: 0.3rem;
      display: flex;
      align-items: center;
    }
    :hover {
      cursor: pointer;
      #sortingIcon {
        opacity: 1;
      }
    }
  }
  th,
  td {
    padding: 0.625rem;
    border-bottom: 1px solid rgba(224, 224, 224, 1);
    height: 52px;
  }
  td {
    font-weight: 500;
    font-size: 1.2rem;
  }
  tbody tr {
    &.selected {
      background-color: #ecf3fb;
    }
    &.expand {
      td:first-child {
        border-left: 1px solid transparent;
      }
      td:last-child {
        border-right: 1px solid transparent;
      }
    }
    &.expanded {
      background-color: #ecf3fb;
      td:first-child {
        border-left: 1px solid rgb(224, 224, 224);
      }
      td:last-child {
        border-right: 1px solid rgb(224, 224, 224);
      }
    }
    &:hover {
      background-color: #1565c00a;
    }
    &.expandable-row {
      &:hover {
        background-color: initial;
        cursor: auto;
      }
      .expandable-content {
        padding-left: 3.3rem;
        width: 100%;
        min-height: 100%;
        height: auto;
        border-right: 1px solid rgb(224, 224, 224);
        border-left: 1px solid rgb(224, 224, 224);
        .content {
          display: inline-block;
          margin: 1rem 1.5rem;
          b {
            margin-right: 0.5rem;
          }
        }
      }
    }
  }
  .no-rows {
    text-align: center;
  }
  .icon-column {
    color: rgba(0, 0, 0, 0.56);
    vertical-align: middle;
  }
}
.pagination {
  display: flex;
  align-items: center;
  justify-content: end;
  gap: 1.5rem;
  font-weight: 400;
  font-size: 1.2rem;
  margin-top: 1rem;
  padding-bottom: 2rem;
  margin-right: 1rem;
  .per-page {
    color: rgba(0, 0, 0, 0.6);
    margin-right: 0.5rem;
  }
  .select {
    font-size: 1rem;
    height: 2.2rem;
  }
}
