.filters {
  display: flex;
  flex-wrap: wrap;
  gap: 1.5rem;
  margin-bottom: 1.6rem;

  .filter-item-btn {
    &.filter-CSV-btn {
      flex: none;
      margin-left: auto;
    }
  }
  .filter-calendar-range {
    display: flex;

    align-items: center;

    gap: 0.8rem;

    .MuiFormHelperText-root {
      position: absolute;

      font-size: 1rem;

      margin: 0;
      bottom: -1.8rem;
    }
  }

  hr {
    height: 0.24rem;
    width: 1.5rem;
    background-color: #020202;
  }

  .filters-calendar-separator {
    font-weight: 200;
    font-size: 3rem;
  }
}

/*
* filters responsiveness behavior
*/
@media (max-width: 949px) {
  .filters .filter-item-btn {
    margin-left: auto;
    flex: none;
  }
}
@mixin two-cols-filters {
  gap: 0;
  .filter-item {
    width: 48%;
    margin-bottom: 1.5rem;
    &:nth-child(2) {
      margin-left: auto;
    }
    .MuiAutocomplete-root,
    .MuiFormControl-root {
      width: 100%;
      display: flex;
    }
  }
  .filter-item-btn {
    margin-left: auto;
  }
  .filter-item-btn ~ .filter-item-btn {
    margin-left: 1.5rem !important;
  }
}

@mixin one-col-filters {
  gap: 0;
  .filter-item {
    width: 100%;
    margin-bottom: 1.5rem;
    .MuiAutocomplete-root,
    .MuiFormControl-root {
      width: 100%;
      display: flex;
      max-width: none;
    }
  }
}

#client-table,
#leads-table {
  @media (max-width: 800px) {
    .filters {
      @include two-cols-filters;
    }
  }
}

#leads-table {
  @media (max-width: 780px) {
    .filters {
      @include one-col-filters;
    }
  }
}

#birthday-table,
#star-table,
#track-table {
  @media (max-width: 620px) {
    .filters {
      @include one-col-filters;
    }
  }
}

#client-table {
  @media (max-width: 440px) {
    .filters {
      @include one-col-filters;
    }
  }
}
