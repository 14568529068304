@use "./colors";

.MuiStack-root {
  ::-webkit-scrollbar {
    width: 0.6rem;
    height: 0.6rem;
  }
}

//Customize the size of the calendar days (S M T W T F S)
.MuiDayCalendar-weekDayLabel {
  font-size: 1.4rem !important;
}

//Customize the size of calendar numbers (1 2 3 ... 31)
.MuiPickersCalendar-weekDayLabel,
.MuiPickersDay-root {
  font-size: 1.4rem !important;
}

//Customize the size of cancel and ok button
//Customize the size of the years chips
.MuiButtonBase-root,
.MuiPickersYear-yearButton {
  font-size: 1.4rem !important;
}
